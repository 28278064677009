import { Spinner } from '@amzn/awsui-components-react';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { logger } from 'modules/core';

export enum Card {
    CreateSpendDistribution = 'CreateSpendDistribution',
    SoftClosePOs = 'SoftClosePOs',
    ConfirmReceipts = 'ConfirmReceipts',
    ReceivePOLines = 'ReceivePOLines',
    ViewRBNI = 'ViewRBNI',
    POsNeedingAttention = 'POsNeedingAttention',
    TopAccounts = 'TopAccounts'
}

export const i18nStrings = {
    dragHandleAriaLabel: 'dragHandleAriaLabel',
    dragHandleAriaDescription: 'dragHandleAriaDescription',
    resizeHandleAriaLabel: 'resizeHandleAriaLabel',
    resizeHandleAriaDescription: 'resizeHandleAriaDescription'
};

type NavigableDivProps = {
    dataLinkId?: string;
    children: ReactNode;
    url: string;
    isPending: boolean;
    error: Error | null;
};
export const NavigableDiv = ({ dataLinkId, children, url, isPending, error }: NavigableDivProps) => {
    const navigate = useNavigate();

    if (error) {
        logger.error(error);
    }

    return isPending || error != null ? (
        <Spinner />
    ) : (
        <div data-link-id={dataLinkId} style={{ cursor: 'pointer', height: '100%' }} onClick={() => navigate(url)}>
            {children}
        </div>
    );
};

export { CreateSpendDistribution } from './CreateSpendDistribution';
export { SoftClosePOs } from './SoftClosePOs';
export { ConfirmReceipts } from './ConfirmReceipts';
export { ReceivePOLines } from './ReceivePOLines';
export { ViewRBNI } from './ViewRBNI';
export { POsNeedingAttention } from './POsNeedingAttention';
export { TopAccounts } from './TopAccounts';
