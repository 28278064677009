import React, { useCallback, useState } from 'react';
import { AppLayout, Header } from '@amzn/awsui-components-react';
import Board, { BoardProps } from '@amzn/awsui-board-components/board';
import { ApplicationState } from 'modules/app/store';
import { useSelector } from 'react-redux';
import { CalendarIcon } from './Icons';

import styles from './Home.module.scss';
import {
    Card,
    CreateSpendDistribution,
    SoftClosePOs,
    ConfirmReceipts,
    ReceivePOLines,
    ViewRBNI,
    POsNeedingAttention,
    TopAccounts
} from '../Cards/Card';

type BoardItems = ReadonlyArray<BoardProps.Item>;

const cards = {
    [Card.CreateSpendDistribution]: <CreateSpendDistribution />,
    [Card.SoftClosePOs]: <SoftClosePOs />,
    [Card.ConfirmReceipts]: <ConfirmReceipts />,
    [Card.ReceivePOLines]: <ReceivePOLines />,
    [Card.ViewRBNI]: <ViewRBNI />,
    [Card.POsNeedingAttention]: <POsNeedingAttention />,
    [Card.TopAccounts]: <TopAccounts />
};

const save = (key: string, value: BoardItems) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const load = (key: string): BoardItems | undefined => {
    const value = localStorage.getItem(key);
    try {
        if (!value) {
            return undefined;
        }
        return JSON.parse(value);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(
            `⚠️ The ${key} value that is stored in localStorage is incorrect. Try to remove the value ${key} from localStorage and reload the page`
        );
    }
};

export const defaultItems: BoardItems = [
    { id: Card.CreateSpendDistribution, data: {} },
    { id: Card.SoftClosePOs, data: {} },
    { id: Card.ConfirmReceipts, data: {} },
    { id: Card.ReceivePOLines, data: {} },
    // { id: Card.ViewRBNI, data: {} }, // disabled for milestone 1
    { id: Card.POsNeedingAttention, data: {} }
    // Not using Infinity for columnSpan because items needs to be JSON-serializable
    // { id: Card.TopAccounts, columnSpan: 999, data: {} } // disabled for milestone 1
];

export const Home = () => {
    const userFirstName = useSelector((state: ApplicationState) => state.user.firstName);

    const widgetItemsKey = 'receiving-dashboard-widgets-layout';
    const [items, setItems] = useState(() => load(widgetItemsKey) ?? defaultItems);

    const handleLayoutChange = useCallback((newValue: BoardItems) => {
        setItems(newValue);
        save(widgetItemsKey, newValue);
    }, []);

    const content = (
        <>
            <Header variant="h1">Welcome, {userFirstName ?? 'Guest'}</Header>
            <Header variant="h3">
                <div className={styles.iconContainer}>
                    <CalendarIcon />
                    <b className={styles.iconText}>
                        {/* Actually computing the days left for MEC is out of scope for milestone 1 */}
                        {new Date().toLocaleDateString('en-US', {
                            weekday: 'long',
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                        })}
                    </b>
                </div>
            </Header>
            <Board
                items={items}
                renderItem={(item, _actions) => cards[item.id as Card]}
                onItemsChange={e => handleLayoutChange(e.detail.items)}
                i18nStrings={i18nStrings}
                empty={<></>}
            />
        </>
    );

    return <AppLayout content={content} navigationHide={true} toolsHide={true} headerSelector="#merp-nav" />;
};

/* istanbul ignore next */
const i18nStrings = {
    navigationAriaLabel: 'navigationAriaLabel', // deprecated
    navigationItemAriaLabel: () => 'navigationItemAriaLabel', // deprecated
    liveAnnouncementDndStarted: () => 'liveAnnouncementDndStarted',
    liveAnnouncementDndCommitted: () => 'liveAnnouncementDndCommitted',
    liveAnnouncementDndDiscarded: () => 'liveAnnouncementDndDiscarded',
    liveAnnouncementDndItemInserted: () => 'liveAnnouncementDndItemInserted',
    liveAnnouncementDndItemReordered: () => 'liveAnnouncementDndItemReordered',
    liveAnnouncementDndItemResized: () => 'liveAnnouncementDndItemResized',
    liveAnnouncementItemRemoved: () => 'liveAnnouncementItemRemoved'
};
