import React from 'react';
import BoardItem from '@amzn/awsui-board-components/board-item';
import { Header } from '@amzn/awsui-components-react';
import { Card, i18nStrings, NavigableDiv } from './Card';
import { ReceiptIcon } from '../Home/Icons';

import styles from './Card.module.scss';
import { useCards } from './hooks/useCards.hook';
import { ConfirmStatus, PurchaseType } from '@amzn/merp-core/models';

export const ConfirmReceipts = () => {
    const { data, isPending, error } = useCards().usePurchaseCount(
        PurchaseType.PURCHASE_ORDER,
        ConfirmStatus.NOT_CONFIRMED
    );

    return (
        <BoardItem
            i18nStrings={i18nStrings}
            header={
                <Header data-header-id={Card.ConfirmReceipts}>
                    <ReceiptIcon />
                    <span className={styles.iconText}>Confirm receipts</span>
                </Header>
            }
        >
            <NavigableDiv
                dataLinkId={Card.ConfirmReceipts}
                url="/purchaseOrder?operation=and&count=1&op0=contains&val0=Unconfirmed"
                isPending={isPending}
                error={error}
            >
                You have <b>{data} POs</b> that need receipt confirmation
            </NavigableDiv>
        </BoardItem>
    );
};
