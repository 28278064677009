import React, { useEffect, useMemo, useState } from 'react';

import {
    Option,
    SortOrder,
    FilterOptions,
    SelectAllState,
    DataGridSortAndFilter,
    SelectedFilterOptions
} from '@amzn/polaris-data-grid';
import { useTranslation } from 'react-i18next';
import { SpaceBetween } from '@amzn/awsui-components-react';
import { useBulkReceivingColumns } from 'modules/bulkEdit/hooks';

export interface ReceivingFilterProps {
    columnName: string;
    isPartial: boolean;
    header: string;
    options?: Option[];
    selectedFilters?: Option[];
    filter?: string;
    onFilterApplied: (
        selectedSortOrder: SortOrder,
        sortProperty: string,
        selectedFilterOptions: SelectedFilterOptions,
        filterProperty: string
    ) => void;
}

export const ReceivingFilter = (props: ReceivingFilterProps) => {
    const { header, filter, options, isPartial, columnName, selectedFilters, onFilterApplied } = props;

    const { t } = useTranslation('bulkReceiving');

    const { getColumnValuesAsync, columnValues, updateColumnValues, loading } = useBulkReceivingColumns();

    const [currentSortOrder, setCurrentSortOrder] = useState(SortOrder.NONE);

    const selectedFilterOptions: SelectedFilterOptions = useMemo(() => {
        return {
            in: true,
            options: selectedFilters ?? columnValues ?? [],
            selectAllState: SelectAllState.NONE
        };
    }, [selectedFilters, columnValues]);

    const [filterText, setFilterText] = useState('');
    const [filterOptions, setFilterOptions] = useState<FilterOptions>({
        isPartial: false,
        options: columnValues ?? []
    });

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setFilterOptions({
            isPartial,
            options: filterOutOptions(columnValues ?? [])
        });
    }, [columnValues]);

    const closeModal = () => {
        setFilterText('');
        setIsVisible(false);
    };

    const filterOutOptions = (optionsToFilter: Option[]) => {
        if (options && filterText !== '') {
            return optionsToFilter.filter(optionToFilter => {
                const optionsVal = (optionToFilter.label as string).toLowerCase();
                return optionsVal.search(filterText.toLowerCase()) >= 0;
            });
        }

        return optionsToFilter;
    };

    const populateFilterOptions = async () => {
        if (options) {
            updateColumnValues(options);
            setFilterOptions({
                isPartial,
                options: filterOutOptions(options)
            });
        } else {
            await getColumnValuesAsync(columnName, filterText);
        }
    };

    const openModal = async () => {
        await populateFilterOptions();
        setIsVisible(true);
    };

    const onDelayedFilterTextChange = async (_text: string) => {
        await populateFilterOptions();
    };

    return (
        <SpaceBetween size="xs" direction="horizontal">
            <span>{header}</span>
            <DataGridSortAndFilter
                sortProperty={'unknown'}
                filterProperty={filter ?? 'unknown'}
                sortHeader={t('common.sort')}
                filterHeader={t('common.filter')}
                filterOptions={filterOptions}
                selectedSortOrder={currentSortOrder}
                selectedFilterOptions={selectedFilterOptions}
                isLoadingOptions={loading}
                emptyOptions={t('common.noResults')}
                confirmLabel={t('actions.apply')}
                cancelLabel={t('actions.cancel')}
                filterText={filterText}
                positionAt={['bottom left']}
                keepComponentInside={false}
                isSortEnabled={false}
                isFilterEnabled={filter !== undefined}
                isVisible={isVisible}
                onOpen={openModal}
                onClose={closeModal}
                onCancel={closeModal}
                onFilterTextChange={(text: string) => setFilterText(text)}
                onDelayedFilterTextChange={onDelayedFilterTextChange}
                onConfirm={(
                    selectedSortOrder: SortOrder,
                    sortProperty: string,
                    selectedFilterOptions: SelectedFilterOptions,
                    filterProperty: string
                ) => {
                    setCurrentSortOrder(selectedSortOrder);
                    onFilterApplied(selectedSortOrder, sortProperty, selectedFilterOptions, filterProperty);
                    closeModal();
                }}
            />
        </SpaceBetween>
    );
};
