import React from 'react';
import BoardItem from '@amzn/awsui-board-components/board-item';
import { Header } from '@amzn/awsui-components-react';
import { Card, i18nStrings, NavigableDiv } from './Card';
import { WalletIcon } from '../Home/Icons';

import styles from './Card.module.scss';
import { ConfirmStatus, PurchaseType } from '@amzn/merp-core/models';
import { useCards } from './hooks/useCards.hook';

export const CreateSpendDistribution = () => {
    const { data, isPending, error } = useCards().usePurchaseCount(
        PurchaseType.PURCHASE_REQUEST,
        ConfirmStatus.NOT_APPLICABLE
    );

    return (
        <BoardItem
            i18nStrings={i18nStrings}
            header={
                <Header data-header-id={Card.CreateSpendDistribution}>
                    <WalletIcon />
                    <span className={styles.iconText}>Create spend distribution</span>
                </Header>
            }
        >
            <NavigableDiv
                dataLinkId={Card.CreateSpendDistribution}
                url="/purchaseRequest?operation=and&count=1&op0=contains&val0=No+Spend"
                isPending={isPending}
                error={error}
            >
                You have <b>{data} PRs</b> that require spend distribution
            </NavigableDiv>
        </BoardItem>
    );
};
