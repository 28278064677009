import React from 'react';
import BoardItem from '@amzn/awsui-board-components/board-item';
import { Header } from '@amzn/awsui-components-react';
import { Card, i18nStrings, NavigableDiv } from './Card';
import { BillIcon } from '../Home/Icons';

import styles from './Card.module.scss';
import { useCards } from './hooks/useCards.hook';

export const SoftClosePOs = () => {
    const { data, isPending, error } = useCards().useFullyReceivedPurchaseCount();

    return (
        <BoardItem
            i18nStrings={i18nStrings}
            header={
                <Header data-header-id={Card.SoftClosePOs}>
                    <BillIcon />
                    <span className={styles.iconText}>Soft close POs</span>
                </Header>
            }
        >
            <NavigableDiv
                dataLinkId={Card.SoftClosePOs}
                url="/receiving?statuses=fully_received"
                isPending={isPending}
                error={error}
            >
                You have <b>{data} POs</b> that need to be soft-closed
            </NavigableDiv>
        </BoardItem>
    );
};
