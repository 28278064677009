/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect } from 'react';

import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import { PropertyFilteringStrings } from './PropertyFilteringStrings';
import { COAPropertyStrings } from './COAPropertyStrings';

interface PropertyFilteringProps {
    paginateFromServerAsyncV2: (filteringQuery?: PropertyFilterProps.Query) => void;
    resetPurchaseList: () => void;
    onViewDashboardClicked?: () => void;
    setDashboardQueryParams: React.Dispatch<React.SetStateAction<PropertyFilterProps.Query | undefined>>;
    dashboardQueryParams?: PropertyFilterProps.Query;
}

export const PropertyFiltering = (props: PropertyFilteringProps) => {
    const { paginateFromServerAsyncV2, resetPurchaseList, setDashboardQueryParams, dashboardQueryParams } = props;

    const defaultQuery = {
        tokens: [],
        operation: 'and'
    } as PropertyFilterProps.Query;

    const onSearchRecords = useCallback(async (detail: PropertyFilterProps.Query) => {
        resetPurchaseList();
        if (detail.tokens.length > 0) {
            await paginateFromServerAsyncV2(detail);
        } else {
            await paginateFromServerAsyncV2();
        }
    }, []);

    useEffect(() => {
        if (dashboardQueryParams) {
            onSearchRecords(dashboardQueryParams);
        }
    }, [dashboardQueryParams]);

    return (
        <PropertyFilter
            query={dashboardQueryParams || defaultQuery}
            filteringProperties={COAPropertyStrings()}
            freeTextFiltering={{ operators: [':'], defaultOperator: ':' }}
            filteringOptions={[]}
            onChange={({ detail }: { detail: PropertyFilterProps.Query }) => {
                setDashboardQueryParams(detail);
            }}
            i18nStrings={PropertyFilteringStrings()}
        />
    );
};
