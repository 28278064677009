import {
    Link,
    Input,
    Table,
    Badge,
    Button,
    Header,
    TokenGroup,
    TableProps,
    Pagination,
    SpaceBetween,
    TokenGroupProps,
    PaginationProps,
    CollectionPreferences,
    NonCancelableCustomEvent,
    CollectionPreferencesProps,
    InputProps
} from '@amzn/awsui-components-react';
import BigNumber from 'bignumber.js';
import { TableRow } from 'modules/core/model';
import { useTranslation } from 'react-i18next';
import { Receipt } from 'modules/purchase/models';
import { I18nFormatter } from 'modules/core/class';
import React, { useCallback, useEffect, useState } from 'react';
import { YesNoModal } from 'modules/core/components';
import { ReceivingFilter } from '../ReceivingFilter';
import { TransactionAlert } from '../TransactionAlert';
import { useBulkReceiving } from 'modules/bulkEdit/hooks';
import { FilteringCriteria } from 'modules/bulkEdit/types';
import { BulkReceivingRow } from 'modules/bulkEdit/models';
import { BulkReceivingExcelActions } from 'modules/bulkEdit/components';
import { ReceivingStatusCell } from '../ReceivingStatusCell';
import { tablePreferencesService } from 'modules/app/services';
import { InvoicedStatusCell } from '../InvoicedStatusCell.tsx';
import { SelectedFilterOptions, SortOrder, Option } from '@amzn/polaris-data-grid';
import { BulkReceivingConfirmSaveModal } from '../BulkReceivingConfirmSaveModal';
import { validateReceivingAmount, validateReceivingQuantity } from 'modules/bulkEdit/validators';
import {
    ENTER_KEY_CODE,
    FILTERING_DELIMITER,
    InvoicedStatusOptions,
    LineTypeOptions,
    NUMBER_OF_ROWS_ABLE_TO_SELECT,
    ReceivingStatusOptions,
    TABLE_PAGE_SIZE
} from 'modules/bulkEdit/constants';
import { DataRetrievalErrorAlert } from '../DataRetrievalErrorAlert';
import { maxEditsReached } from 'modules/bulkEdit/validators/maxEditsReached';
import { EmptyTable } from 'modules/app/components/CommonTableComponents/EmptyTable';
import { useSearchParams } from 'react-router-dom';

const TABLE_PREFERENCES_KEY = 'BulkReceiving';
const cypress = 'bulkReceiving';

type ReceivingRow = TableRow<BulkReceivingRow>;

export const BulkReceiving = () => {
    const { t } = useTranslation('bulkReceiving');

    const defaultPreferences: CollectionPreferencesProps.Preferences = tablePreferencesService.getTablePreferences(
        TABLE_PREFERENCES_KEY
    ) ?? { pageSize: TABLE_PAGE_SIZE };

    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [purchaseLines, setPurchaseLines] = useState<ReceivingRow[]>([]);
    const [selectedPurchaseLines, setSelectedPurchaseLines] = useState<ReceivingRow[]>([]);
    const [filterTokens, setFilterTokens] = useState<string[]>([]);
    const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
    const [cancelModalIsVisible, setCancelModalIsVisible] = useState(false);
    const [receipts, updateReceipts] = useState(new Map<string, Receipt>());
    const [searchInput, updateSearchInput] = useState('');
    const [searchInputDisabled, updateSearchInputDisabled] = useState(false);
    const [isReceiveInFull, updateIsReceiveInFull] = useState(false);
    const [searchInputPlaceholder, updateSearchInputPlaceHolder] = useState('Search by PO Number');
    const [selectedRowCountByPageNumber, setSelectedRowCountByPageNumber] = useState(new Map<number, number>());
    const [preferences, updatePreferences] = useState(defaultPreferences);
    const [excelAlert, updateExcelAlert] = useState(() => <></>);

    const {
        rows,
        loading,
        receivingTx,
        hasNextPage,
        filteringCriteria,
        paginateAsync,
        bulkReceiveAsync,
        dismissTransaction,
        updateFilteringCriteria,
        dataRetrievalErrorMessage
    } = useBulkReceiving();

    const retainNonempty = (searchParams: URLSearchParams) =>
        Object.fromEntries(Array.from(searchParams).filter(pair => pair[1]));

    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (searchParams.toString() === '') {
            paginateAsync(filteringCriteria);
        } else {
            const filtersFromUrl = retainNonempty(searchParams);
            updateFilteringCriteria(filtersFromUrl);
        }
    }, []);

    useEffect(() => {
        if (filteringCriteria == null) return;
        setSearchParams(retainNonempty(new URLSearchParams(filteringCriteria)));
    }, [filteringCriteria]);

    useEffect(() => {
        if (!rows) return;

        setPageCount(Math.ceil(rows.length / (defaultPreferences?.pageSize ?? TABLE_PAGE_SIZE)));
    }, [rows?.length]);

    useEffect(() => {
        setPageItems();
    }, [currentPage]);

    useEffect(() => {
        if (loading) return;

        setPageItems();
    }, [loading, rows]);

    useEffect(() => {
        if (!filteringCriteria) return;

        const tokens: string[] = [];
        const entries = Object.entries(filteringCriteria);

        for (const [key, values] of entries) {
            if (!values) continue;

            tokens.push(...values.split(FILTERING_DELIMITER).map(value => `${key}::${value}`));
        }

        if (tokens.length > 0) {
            updateSearchInputDisabled(true);
            updateSearchInputPlaceHolder('Remove all filters to enable search');
        } else {
            updateSearchInputDisabled(false);
            updateSearchInputPlaceHolder('Search by PO Number');
        }

        setFilterTokens(tokens);
        setCurrentPage(1);
    }, [filteringCriteria]);

    const setPageItems = () => {
        if (!rows) return;
        const { pageSize } = preferences;

        setPurchaseLines(rows.slice(pageSize! * (currentPage - 1), Math.min(pageSize! * currentPage, rows.length)));
    };

    const toTokenItem = (val: string) => {
        const [key, value] = val.split('::');
        return {
            label: t(key),
            description: t(value)
        };
    };

    const getSelectedFilters = (key: string, options?: Option[]) => {
        if (!filteringCriteria || !filteringCriteria[key]) return [];

        // For dynamically loaded filters the label and value are the same:
        //   PO No has "2D-123456" for both label and value.
        // For filters that can only take a value from a set of options the label and value are different:
        //   Receiving Status has "Fully Received" as label but "fully_received" as value.
        const getLabel = (value: string) => (options?.find(opt => opt.value === value)?.label ?? value) as string;
        return filteringCriteria[key].split(FILTERING_DELIMITER).map(value => ({
            value,
            label: getLabel(value)
        }));
    };

    const onPaginationChange = (e: NonCancelableCustomEvent<PaginationProps.ChangeDetail>) => {
        const { currentPageIndex } = e.detail;

        setCurrentPage(currentPageIndex);
        if (currentPageIndex > pageCount && hasNextPage) {
            setPageCount(currentPageIndex);
        }
    };

    const onSearchInputKeyDown = ({ detail }: CustomEvent<InputProps.KeyDetail>): void => {
        if (ENTER_KEY_CODE === detail.keyCode) {
            const field = 'poNo';

            updateFilteringCriteria(prev => {
                const copy: FilteringCriteria = Object.assign({}, prev ?? {});

                if (searchInput) {
                    copy[field] = searchInput;
                } else {
                    delete copy[field];
                }

                return copy;
            });

            updateSearchInput('');
        }
    };

    const onNextPageClick = (e: NonCancelableCustomEvent<PaginationProps.PageClickDetail>) => {
        const { requestedPageAvailable, requestedPageIndex } = e.detail;

        if (!requestedPageAvailable && hasNextPage) {
            paginateAsync(filteringCriteria);
        }
        if (!hasNextPage && requestedPageIndex > pageCount) {
            setCurrentPage(currentPage);
        }
    };

    const onConfirmPreferences = (event: NonCancelableCustomEvent<CollectionPreferencesProps.Preferences<unknown>>) => {
        const pref = event.detail;

        updatePreferences(pref);
        tablePreferencesService.save(TABLE_PREFERENCES_KEY, pref);
    };

    const handleSelectionChange = (selectedItems: ReceivingRow[]) => {
        if (hasTooManySelections(selectedItems.length)) {
            return;
        }

        setSelectedPurchaseLines(selectedItems);
    };

    const hasTooManySelections = (upcomingSelectedRowsCountForCurrentPage: number) => {
        if (!currentPage) return true;

        const temporaryMap = new Map(selectedRowCountByPageNumber);
        temporaryMap.set(currentPage, upcomingSelectedRowsCountForCurrentPage);

        const upcomingSelectedRowsCountForAllPages = Array.from(temporaryMap.values()).reduce(
            (acc, val) => acc + val,
            0
        );

        if (upcomingSelectedRowsCountForAllPages > NUMBER_OF_ROWS_ABLE_TO_SELECT) {
            return true;
        }

        setSelectedRowCountByPageNumber(temporaryMap);
        return false;
    };

    const onFiltering = (
        selectedSortOrder: SortOrder,
        _sortProperty: string,
        selectedFilterOptions: SelectedFilterOptions,
        field: string
    ) => {
        updateFilteringCriteria(prev => {
            const copy: FilteringCriteria = Object.assign({}, prev ?? {});

            const tokens = selectedFilterOptions.options.map(o => o.value);

            if (!tokens.length) {
                delete copy[field];
            } else {
                copy[field] = tokens.join(FILTERING_DELIMITER);
            }

            return copy;
        });
    };

    const onRowEdited = (row: ReceivingRow, _column: TableProps.ColumnDefinition<ReceivingRow>, value: unknown) => {
        const newRow = BulkReceivingRow.from(row.payload);

        const isNotValid = newRow.isQuantityBased ? validateReceivingQuantity : validateReceivingAmount;

        if (isNotValid(newRow, value as number, receipts.size)) {
            return;
        }

        const receipt = newRow.receive(value);
        if (new BigNumber(receipt.receiptAmount).isZero()) {
            receipts.delete(newRow.id);
        } else {
            receipts.set(newRow.id, receipt);
        }

        updateReceipts(receipts);

        setPurchaseLines(previous => {
            const newItems = [...previous];

            const index = newItems.findIndex(r => r.payload.id === newRow.id);
            newItems[index].payload = newRow;

            return newItems;
        });
    };

    const generateReceiptsFromSelectedItems = useCallback(() => {
        if (!selectedPurchaseLines) {
            return [];
        }

        return selectedPurchaseLines.map(r => BulkReceivingRow.from(r.payload).receiveInFull());
    }, [selectedPurchaseLines, setSelectedPurchaseLines]);

    const onConfirmClick = useCallback(async () => {
        setConfirmModalIsVisible(false);
        await bulkReceiveAsync(
            isReceiveInFull ? generateReceiptsFromSelectedItems() : Array.from(receipts.values()),
            isReceiveInFull
        );
        setCurrentPage(1);
        updateReceipts(new Map());
        setSelectedPurchaseLines([]);
    }, [generateReceiptsFromSelectedItems, receipts, isReceiveInFull]);

    const onSaveCanceled = () => {
        setConfirmModalIsVisible(false);
    };

    const onYesCancel = async () => {
        setCancelModalIsVisible(false);

        updateReceipts(new Map());
        updateFilteringCriteria(prev => Object.assign({}, prev ?? {}));
        setCurrentPage(1);
    };

    const onDontCancel = () => {
        setCancelModalIsVisible(false);
    };

    const onSaveClick = () => {
        updateIsReceiveInFull(false);
        setConfirmModalIsVisible(true);
    };

    const onReceiveInFullClick = () => {
        updateIsReceiveInFull(true);
        setConfirmModalIsVisible(true);
    };

    const onFilterTokenRemoved = ({ itemIndex }: TokenGroupProps.DismissDetail) => {
        if (!filteringCriteria) return;

        const token = filterTokens[itemIndex];
        const [key, value] = token.split('::');

        updateFilteringCriteria(prev => {
            if (!prev) return;

            const values = prev[key];
            if (!values) return prev;

            const arr = values.split(FILTERING_DELIMITER);
            const i = arr.indexOf(value);

            if (i >= 0) {
                arr.splice(i, 1);
            }

            return { ...prev, [key]: arr.join(FILTERING_DELIMITER) };
        });
    };

    const onClearFilters = () => {
        updateFilteringCriteria({});
    };

    const amountEditDisabled = (row: BulkReceivingRow): boolean => {
        return receivingTx?.loading || row.isQuantityBased || maxEditsReached(receipts.size, row.receivingAmount);
    };

    const quantityEditDisabled = (row: BulkReceivingRow): boolean => {
        return receivingTx?.loading || !row.isQuantityBased || maxEditsReached(receipts.size, row.receivingQuantity);
    };

    const columns: TableProps.ColumnDefinition<ReceivingRow>[] = [
        {
            id: 'poId',
            minWidth: 150,
            header: (
                <ReceivingFilter
                    columnName="po_number"
                    isPartial={false}
                    header={t('poId')}
                    filter="poNo"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('poNo')}
                />
            ),
            cell: row => (
                <Link href={`${window.location.origin}/purchaseOrder/${row.payload.poId}`} target="_blank">
                    {row.payload.poNo}
                </Link>
            )
        },
        {
            id: 'lineNo',
            minWidth: 150,
            header: t('lineNo'),
            cell: row => row.payload.lineNo,
            sortingField: 'lineNo',
            sortingComparator: (a, b) => a.payload.lineNo! - b.payload.lineNo!
        },
        {
            id: 'lineDesc',
            header: t('lineDesc'),
            cell: row => row.payload.lineDescription
        },
        {
            id: 'poCreationTime',
            header: t('poCreationTime'),
            cell: row => I18nFormatter.date(t, row.payload.poCreationTime)
        },
        {
            id: 'lineType',
            minWidth: 150,
            header: (
                <ReceivingFilter
                    columnName="lineType"
                    options={LineTypeOptions}
                    isPartial={false}
                    header={t('lineType')}
                    filter="lineTypes"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('lineTypes', LineTypeOptions)}
                />
            ),
            cell: row => {
                return (
                    <Badge color={row.payload.isQuantityBased ? 'green' : 'blue'}>
                        {t(row.payload.isQuantityBased ? 'quantityLine' : 'amountLine')}
                    </Badge>
                );
            }
        },
        { id: 'currency', minWidth: 120, header: t('currency'), cell: row => row.payload.currency?.toUpperCase() },
        {
            id: 'totalInvoiced',
            header: t('totalInvoiced'),
            cell: row => I18nFormatter.amount(t, row.payload.totalInvoiced)
        },
        //{ id: 'pendingInvoiced', header: t('pendingInvoiced'), cell: row => row.payload.pendingInvoiced }, awaiting this task: https://playbook2.amazon.com/project/901302/task/20791106
        {
            id: 'receivedQuantity',
            header: t('receivedQuantity'),
            cell: row => (row.payload.isQuantityBased ? I18nFormatter.amount(t, row.payload.receivedQuantity) : '-')
        },
        {
            id: 'total',
            header: t('total'),
            cell: row => I18nFormatter.amount(t, row.payload.totalReceived)
        },
        {
            id: 'quantity',
            header: t('quantity'),
            cell: row => (row.payload.isQuantityBased ? I18nFormatter.amount(t, row.payload.quantity) : '-')
        },
        {
            id: 'amount',
            minWidth: 150,
            header: t('amount'),
            cell: row => I18nFormatter.amount(t, row.payload.amount)
        },
        {
            id: 'unusedQuantity',
            header: t('unusedQuantity'),
            cell: row =>
                row.payload.isQuantityBased ? I18nFormatter.amount(t, row.payload.receivingUnusedQuantity) : '-'
        },
        {
            id: 'unusedAmount',
            header: t('unusedAmount'),
            cell: row => {
                return I18nFormatter.amount(t, row.payload.receivingUnusedAmount);
            }
        },
        {
            id: 'price',
            header: t('price'),
            cell: row => (row.payload.isQuantityBased ? I18nFormatter.amount(t, row.payload.unitPrice) : '-')
        },
        {
            id: 'receivingQuantity',
            header: t('receivingQuantity'),
            cell: row => (row.payload.isQuantityBased ? I18nFormatter.amount(t, row.payload.receivingQuantity) : '-'),
            editConfig: {
                ariaLabel: t('quantity'),
                editIconAriaLabel: 'editable',
                validation: (row, value) => validateReceivingQuantity(row.payload, value, receipts.size),
                editingCell: (item, { currentValue, setValue }) => {
                    return (
                        <Input
                            autoFocus={true}
                            value={currentValue}
                            onChange={event => setValue(event.detail.value)}
                            disabled={quantityEditDisabled(item.payload)}
                        />
                    );
                }
            }
        },
        {
            id: 'receivingAmount',
            header: t('receivingAmount'),
            cell: row => (!row.payload.isQuantityBased ? I18nFormatter.amount(t, row.payload.receivingAmount) : '-'),
            editConfig: {
                ariaLabel: t('amount'),
                editIconAriaLabel: 'editable',
                validation: (row, value) => validateReceivingAmount(row.payload, value, receipts.size),
                editingCell: (item, { currentValue, setValue }) => (
                    <Input
                        autoFocus={true}
                        value={currentValue}
                        onChange={event => setValue(event.detail.value)}
                        disabled={amountEditDisabled(item.payload)}
                    />
                )
            }
        },
        {
            id: 'status',
            header: (
                <ReceivingFilter
                    columnName="receivingStatus"
                    options={ReceivingStatusOptions}
                    isPartial={false}
                    header={t('status')}
                    filter="statuses"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('statuses', ReceivingStatusOptions)}
                />
            ),
            cell: row => <ReceivingStatusCell status={row.payload.status!} />
        },
        {
            id: 'invoiceStatus',
            header: (
                <ReceivingFilter
                    columnName="invoiceStatus"
                    options={InvoicedStatusOptions}
                    isPartial={false}
                    header={t('invoiceStatus')}
                    filter="invoiceStatuses"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('invoiceStatuses', InvoicedStatusOptions)}
                />
            ),
            cell: row => <InvoicedStatusCell status={row.payload.invoiceStatus!} />
        },
        {
            id: 'lastReceivedDate',
            header: t('lastReceivedDate'),
            cell: row => I18nFormatter.date(t, row.payload.lastReceivedDate)
        },
        {
            id: 'companyCode',
            header: (
                <ReceivingFilter
                    columnName="company_Code"
                    isPartial={false}
                    header={t('companyCode')}
                    filter="companyCode"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('companyCode')}
                />
            ),
            cell: row => row.payload.companyCode
        },
        {
            id: 'locationName',
            header: (
                <ReceivingFilter
                    columnName="location_name"
                    isPartial={false}
                    header={t('locationName')}
                    filter="locationName"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('locationName')}
                />
            ),
            cell: row => row.payload.locationName
        },
        {
            id: 'location',
            header: (
                <ReceivingFilter
                    columnName="location_code"
                    isPartial={false}
                    header={t('location')}
                    filter="location"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('location')}
                />
            ),
            cell: row => row.payload.location
        },
        {
            id: 'costCenter',
            header: (
                <ReceivingFilter
                    columnName="cost_center"
                    isPartial={false}
                    header={t('costCenter')}
                    filter="costCenter"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('costCenter')}
                />
            ),
            cell: row => row.payload.costCenter
        },
        {
            id: 'glAccount',
            header: (
                <ReceivingFilter
                    columnName="gl_account_code"
                    isPartial={false}
                    header={t('glAccount')}
                    filter="glAccount"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('glAccount')}
                />
            ),
            cell: row => row.payload.glAccount
        },
        {
            id: 'projectCode',
            header: (
                <ReceivingFilter
                    columnName="project_code"
                    isPartial={false}
                    header={t('projectCode')}
                    filter="projectCode"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('projectCode')}
                />
            ),
            cell: row => row.payload.projectCode
        },
        {
            id: 'productLine',
            header: (
                <ReceivingFilter
                    columnName="product_line"
                    isPartial={false}
                    header={t('productLine')}
                    filter="productLine"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('productLine')}
                />
            ),
            cell: row => row.payload.productLine
        },
        {
            id: 'channel',
            header: (
                <ReceivingFilter
                    columnName="channel"
                    isPartial={false}
                    header={t('channel')}
                    filter="channel"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('channel')}
                />
            ),
            cell: row => row.payload.channel
        },
        {
            id: 'poRequestor',
            header: (
                <ReceivingFilter
                    columnName="po_requester"
                    isPartial={false}
                    header={t('poRequestor')}
                    filter="poRequester"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('poRequester')}
                />
            ),
            cell: row => row.payload.poRequestor
        },
        {
            id: 'poCreator',
            header: (
                <ReceivingFilter
                    columnName="po_creator"
                    isPartial={false}
                    header={t('poCreator')}
                    filter="poCreator"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('poCreator')}
                />
            ),
            cell: row => row.payload.poCreator
        },
        {
            id: 'vendor',
            header: (
                <ReceivingFilter
                    columnName="vendor"
                    isPartial={false}
                    header={t('vendor')}
                    filter="vendor"
                    onFilterApplied={onFiltering}
                    selectedFilters={getSelectedFilters('vendor')}
                />
            ),
            cell: row => row.payload.vendor
        }
    ];

    columns.forEach(c => {
        if (!c.minWidth) {
            c.minWidth = 200;
        }
    });

    const disableSelction = (row: ReceivingRow) => {
        return (
            (!row.payload.isQuantityBased && row.payload.receivingUnusedAmount === 0.0) ||
            (row.payload.isQuantityBased && row.payload.receivingUnusedQuantity === 0.0)
        );
    };

    const bulkReceivingHeader = (
        <Header
            variant="h3"
            actions={
                <SpaceBetween direction="horizontal" size="s">
                    <BulkReceivingExcelActions
                        filteringCriteria={filteringCriteria}
                        disabled={receivingTx?.loading || selectedPurchaseLines.length > 0}
                        updateAlerts={updateExcelAlert}
                    />
                    <Button
                        disabled={receivingTx?.loading || selectedPurchaseLines.length === 0 || receipts.size > 0}
                        variant="primary"
                        loading={receivingTx?.loading}
                        onClick={onReceiveInFullClick}
                        data-cy={`${cypress}.receiveInFullBtn`}
                    >
                        {t('actions.receiveInFull')}
                    </Button>
                    <Button
                        data-cy={`${cypress}.cancelBtn`}
                        disabled={loading || receivingTx?.loading || !receipts.size}
                        onClick={() => setCancelModalIsVisible(true)}
                    >
                        {t('actions.cancel')}
                    </Button>
                    <Button
                        disabled={loading || !receipts.size || selectedPurchaseLines.length > 0}
                        variant="primary"
                        loading={receivingTx?.loading}
                        data-cy={`${cypress}.saveBtn`}
                        onClick={onSaveClick}
                    >
                        {t('actions.save')}
                    </Button>
                </SpaceBetween>
            }
        >
            {t('header')}
        </Header>
    );

    return (
        <SpaceBetween direction="vertical" size="m">
            <TransactionAlert transaction={receivingTx} onDismiss={dismissTransaction} />
            <DataRetrievalErrorAlert dataRetrievalErrorMessage={dataRetrievalErrorMessage} />
            {excelAlert}
            <Table
                stickyHeader={true}
                header={bulkReceivingHeader}
                empty={<EmptyTable title={t('common.noResults')} subtitle={t('common.noResults')} />}
                pagination={
                    <Pagination
                        openEnd={hasNextPage}
                        disabled={loading}
                        pagesCount={pageCount}
                        currentPageIndex={currentPage}
                        onNextPageClick={onNextPageClick}
                        onChange={onPaginationChange}
                    />
                }
                items={purchaseLines}
                loading={loading}
                resizableColumns
                selectionType={receivingTx ? undefined : 'multi'}
                columnDefinitions={columns}
                loadingText={t('common.loading')}
                onSelectionChange={({ detail }) => handleSelectionChange(detail.selectedItems)}
                stickyColumns={{ first: 2, last: 0 }}
                columnDisplay={preferences.contentDisplay}
                selectedItems={selectedPurchaseLines}
                isItemDisabled={item => disableSelction(item)}
                submitEdit={onRowEdited}
                filter={
                    <SpaceBetween size="s" direction="vertical">
                        <Input
                            onChange={({ detail }) => {
                                updateSearchInput(detail.value.trimStart().trimEnd());
                            }}
                            onKeyDown={onSearchInputKeyDown}
                            placeholder={searchInputPlaceholder}
                            value={searchInput}
                            disabled={searchInputDisabled}
                        />
                        <TokenGroup
                            onDismiss={e => onFilterTokenRemoved(e.detail)}
                            items={filterTokens.map(toTokenItem)}
                            limit={6}
                        />
                        <div>
                            {filterTokens.length > 1 && <Button onClick={onClearFilters}>{t('clearFilters')}</Button>}
                        </div>
                    </SpaceBetween>
                }
                preferences={
                    <CollectionPreferences
                        title={t('preferences.title')}
                        confirmLabel={t('preferences.confirm')}
                        cancelLabel={t('preferences.cancel')}
                        preferences={preferences}
                        onConfirm={onConfirmPreferences}
                        contentDisplayPreference={{
                            title: 'Column preferences',
                            description: 'Customize the columns visibility and order.',
                            options: [
                                { id: 'poId', label: t('poId'), alwaysVisible: true },
                                { id: 'lineNo', label: t('lineNo'), alwaysVisible: true },
                                { id: 'lineDesc', label: t('lineDesc') },
                                { id: 'poCreationTime', label: t('poCreationTime') },
                                { id: 'lineType', label: t('lineType') },
                                { id: 'currency', label: t('currency') },
                                { id: 'totalInvoiced', label: t('totalInvoiced') },
                                //{ id: 'pendingInvoiced', label: t('pendingInvoiced') },link to task https://playbook2.amazon.com/project/901302/task/20791106
                                { id: 'receivedQuantity', label: t('receivedQuantity') },
                                { id: 'total', label: t('total') },
                                { id: 'quantity', label: t('quantity') },
                                { id: 'amount', label: t('amount') },
                                { id: 'unusedQuantity', label: t('unusedQuantity') },
                                { id: 'unusedAmount', label: t('unusedAmount') },
                                { id: 'price', label: t('price') },
                                {
                                    id: 'receivingQuantity',
                                    label: t('receivingQuantity'),
                                    alwaysVisible: true
                                },
                                { id: 'receivingAmount', label: t('receivingAmount'), alwaysVisible: true },
                                { id: 'status', label: t('status') },
                                { id: 'invoiceStatus', label: t('invoiceStatus') },
                                { id: 'lastReceivedDate', label: t('lastReceivedDate') },
                                { id: 'locationName', label: t('locationName') },
                                { id: 'companyCode', label: t('companyCode') },
                                { id: 'location', label: t('location') },
                                { id: 'costCenter', label: t('costCenter') },
                                { id: 'glAccount', label: t('glAccount') },
                                { id: 'projectCode', label: t('projectCode') },
                                { id: 'productLine', label: t('productLine') },
                                { id: 'channel', label: t('channel') },
                                { id: 'poCreator', label: t('poCreator') },
                                { id: 'poRequestor', label: t('poRequestor') },
                                { id: 'vendor', label: t('vendor') }
                            ]
                        }}
                    />
                }
            />
            <BulkReceivingConfirmSaveModal
                isVisible={confirmModalIsVisible}
                isReceiveInFull={isReceiveInFull}
                selectedItemsCount={isReceiveInFull ? selectedPurchaseLines.length : receipts.size}
                onCancel={onSaveCanceled}
                onConfirm={onConfirmClick}
            />
            <YesNoModal
                content={t('confirmCancel.content', { count: receipts?.size })}
                title={t('confirmCancel.header')}
                visible={cancelModalIsVisible}
                yesFunction={onYesCancel}
                noFunction={onDontCancel}
                onDismiss={onDontCancel}
            />
        </SpaceBetween>
    );
};
