import React from 'react';
import BoardItem from '@amzn/awsui-board-components/board-item';
import { Card, i18nStrings, NavigableDiv } from './Card';
import { Header } from '@amzn/awsui-components-react';
import { TransferIcon } from '../Home/Icons';

import styles from './Card.module.scss';
import { PurchaseLineCountType, useCards } from './hooks/useCards.hook';

export const ReceivePOLines = () => {
    const { data, isPending, error } = useCards().usePurchaseLineCount(PurchaseLineCountType.PENDING_RECEIVING);

    return (
        <BoardItem
            i18nStrings={i18nStrings}
            header={
                <Header data-header-id={Card.ReceivePOLines}>
                    <TransferIcon />
                    <span className={styles.iconText}>Receive PO lines</span>
                </Header>
            }
        >
            <NavigableDiv
                dataLinkId={Card.ReceivePOLines}
                url="/receiving?statuses=partially_received|||no_receiving"
                isPending={isPending}
                error={error}
            >
                You have <b>{data} PO lines</b> that need to be received
            </NavigableDiv>
        </BoardItem>
    );
};
