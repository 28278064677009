import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Error } from 'modules/error/views';
import { resetPurchase } from 'modules/app/store';
import { RollUpRoutes } from 'modules/rollups/routes';
import { NavBar } from 'modules/app/components/navbar';
import { PoRoutes } from 'modules/purchaseOrder/routes';
import { MappingRoutes } from 'modules/mappings/routes';
import { UserPreferences } from 'modules/preferences/views';
import { AuditDetail, AuditList } from 'modules/audit/views';
import { RequisitionRoutes } from 'modules/requisition/routes';
import { BudgetRoutes } from 'modules/budgetManagement/routes';
import { BulkEditCoa } from 'modules/accountingStrings/views';
import { BulkEdit, BulkReceivingView } from 'modules/bulkEdit/views';
import { CustomFieldAndSelection } from 'modules/customCategory/views';
import { OnboardingRoutes } from 'modules/onboarding/routes';
import { AppNotifications } from 'modules/app/components/appNotifications';
import { Home } from 'modules/home/Home/Home';

export function AppRoutes(): JSX.Element {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const PageRoutes = {
        Base: '/',
        BasePR: '/purchaseRequest/*',
        BasePO: '/purchaseOrder/*',
        PurchaseOrder: 'purchaseOrder',
        Preferences: '/preferences',
        Audit: '/audit/:purchaseId',
        AuditDetail: '/audit/:purchaseId/:lineVersionLineNumber',
        Mappings: '/mappings/*',
        RollUps: '/rollups/*',
        BulkEdit: '/bulkEdit',
        BulkEditCoa: '/bulkEdit/accountingStrings',
        Budget: '/budget/*',
        BulkReceiving: '/receiving',
        CustomCategory: '/customCategory',
        Onboarding: '/onboarding/*'
    };

    useEffect(() => {
        dispatch(resetPurchase());
    }, [pathname]);

    return (
        <div>
            <NavBar />
            <AppNotifications />
            <Routes>
                <Route path={PageRoutes.Base} element={<Home />} />
                <Route path={PageRoutes.Preferences} element={<UserPreferences />} />
                <Route path={PageRoutes.BasePR} element={<RequisitionRoutes />} />
                <Route path={PageRoutes.BasePO} element={<PoRoutes />} />
                <Route path={PageRoutes.Audit} element={<AuditList />} />
                <Route path={PageRoutes.AuditDetail} element={<AuditDetail />} />
                <Route path={PageRoutes.Mappings} element={<MappingRoutes />} />
                <Route path={PageRoutes.RollUps} element={<RollUpRoutes />} />
                <Route path={PageRoutes.BulkEdit} element={<BulkEdit />} />
                <Route path={PageRoutes.BulkReceiving} element={<BulkReceivingView />} />
                <Route path={PageRoutes.BulkEditCoa} element={<BulkEditCoa />} />
                <Route path={PageRoutes.Budget} element={<BudgetRoutes />} />
                <Route path={PageRoutes.CustomCategory} element={<CustomFieldAndSelection />} />
                <Route path={PageRoutes.Onboarding} element={<OnboardingRoutes />} />
                <Route path="/dashboard/purchaseOrder" element={<Navigate to="/purchaseOrder" />} />
                <Route path="/dashboard/purchaseRequest" element={<Navigate to="/purchaseRequest" />} />
                <Route path="*" element={<Error message="error.urlError" btnContent="error.urlBtn" />} />
            </Routes>
        </div>
    );
}
