import { CSVTemplate } from 'modules/dashboard/models/CSVTemplate';
import { unparse } from 'papaparse';

export const handleCSVDownload = (csvTemplate: CSVTemplate, filename: string): void => {
    if (!csvTemplate) return;

    const headers = csvTemplate.getHeaders();
    const data = csvTemplate.getCSVData();

    const csvContent = unparse([headers, ...data], {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        newline: '\n'
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);

    const csvLink = document.createElement('a');
    csvLink.setAttribute('href', url);
    csvLink.setAttribute('download', filename);
    csvLink.click();

    window.URL.revokeObjectURL(url);
    csvLink.remove();
};
