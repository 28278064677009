import React from 'react';
import BoardItem from '@amzn/awsui-board-components/board-item';
import { Card, i18nStrings } from './Card';
import { Box, Header, KeyValuePairs, Spinner } from '@amzn/awsui-components-react';
import { PurchaseLineCountType, useCards } from './hooks/useCards.hook';
import { logger } from 'modules/core';

export const POsNeedingAttention = () => {
    const { data, error } = useCards().usePurchaseLineCount(PurchaseLineCountType.OVER_INVOICED);

    if (error) {
        logger.error(error);
    }

    return (
        <BoardItem
            i18nStrings={i18nStrings}
            header={<Header data-header-id={Card.POsNeedingAttention}>Purchase orders needing attention</Header>}
        >
            <KeyValuePairs
                columns={1}
                items={[
                    {
                        label: 'Over-invoiced',
                        value: (
                            <Box fontSize="display-l" fontWeight="light">
                                {data != null && error == null ? data : <Spinner />}
                            </Box>
                        )
                    }
                ]}
            />
        </BoardItem>
    );
};
