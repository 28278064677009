import React from 'react';
import BoardItem from '@amzn/awsui-board-components/board-item';
import { Card, i18nStrings } from './Card';
import { Header } from '@amzn/awsui-components-react';
import { ReportIcon } from '../Home/Icons';

import styles from './Card.module.scss';

export const ViewRBNI = () => {
    return (
        <BoardItem
            i18nStrings={i18nStrings}
            header={
                <Header data-header-id={Card.ViewRBNI}>
                    <ReportIcon />
                    <span className={styles.iconText}>View RBNI dashboard</span>
                </Header>
            }
        >
            Go to Received But Not Invoiced (RBNI) Exposure Dashboard
        </BoardItem>
    );
};
