import { PropertyFilterProps } from '@amzn/awsui-components-react';

const mapOperator: Record<string, string> = {
    '<': 'lt',
    '<=': 'lte',
    '>': 'gt',
    '>=': 'gte',
    ':': 'contains',
    '!:': 'doesNotcontain',
    '=': 'eq',
    '!=': 'neq',
    '^': 'startsWith',
    '!^': 'doesNotStartWith'
};

export function toSearchParams(query: PropertyFilterProps.Query | undefined): URLSearchParams {
    if (query == null || query.tokens.length === 0) return new URLSearchParams();
    let encoded = `operation=${query.operation}`;
    const tokens = query.tokens.map(t => ({ op: mapOperator[t.operator], key: t.propertyKey, val: t.value }));
    encoded += `&count=${tokens.length}`;
    for (let i = 0; i < tokens.length; i++) {
        const t = tokens[i];
        encoded += `&op${i}=${t.op}&val${i}=${encodeURIComponent(t.val)}`;
        if (t.key) {
            encoded += `&key${i}=${encodeURIComponent(t.key)}`;
        }
    }
    return new URLSearchParams(encoded);
}

export function fromSearchParams(searchParams: URLSearchParams): PropertyFilterProps.Query {
    const operationIn = searchParams.get('operation') ?? 'and';
    const operation = ['and', 'or'].includes(operationIn) ? (operationIn as PropertyFilterProps.JoinOperation) : 'and';
    const tokens: PropertyFilterProps.Token[] = [];
    const count = parseInt(searchParams.get('count') ?? '0');
    for (let i = 0; i < count; i++) {
        const op = searchParams.get(`op${i}`);
        const value = searchParams.get(`val${i}`);
        const propertyKey = searchParams.get(`key${i}`) ?? undefined; // optional in free-text filtering

        // Operator and value are mandatory
        if (op == null || value == null) {
            continue;
        }

        const keyIndex = Object.values(mapOperator).indexOf(op);
        if (keyIndex < 1) {
            continue;
        }
        const operator = Object.keys(mapOperator)[keyIndex];

        // For now, only support free-text contains and property equality
        if (propertyKey == null && operator !== ':') {
            continue;
        }
        if (propertyKey != null && operator !== '=') {
            continue;
        }

        tokens.push({ operator, propertyKey, value });
    }
    return { tokens, operation };
}
