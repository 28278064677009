import './wdyr';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from '@aws-amplify/core';

import 'assets/styles/index.scss';
import 'modules/core/config/i18n';

import { MainRoutes } from 'modules/app/routes';
import { logger, AuthConfig, ApiEndpoints, AppConfig } from 'modules/core';
import { Loading } from 'modules/core/components';
import { initPrecisionService } from 'modules/core/services';
import * as serviceWorker from './serviceWorker';
import { configureStore } from 'modules/app/store';
import { Provider } from 'react-redux';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';

const config = Amplify.configure({ Auth: AuthConfig, API: { endpoints: ApiEndpoints } });
logger.debug('AWS Config', config);
initPrecisionService();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1000 * 60 * 60 * 1
        }
    }
});
const persister = createSyncStoragePersister({
    storage: window.localStorage
});

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Suspense fallback={<Loading />}>
        <Provider store={store}>
            <PersistQueryClientProvider
                client={queryClient}
                persistOptions={{ persister, buster: AppConfig.reactQueryCacheBuster }}
            >
                <BrowserRouter>
                    <MainRoutes />
                </BrowserRouter>
                {AppConfig.showReactQueryDevTools && <ReactQueryDevtools initialIsOpen={false} />}
            </PersistQueryClientProvider>
        </Provider>
    </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
