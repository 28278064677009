import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore, Store } from 'redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { Purchase, PurchaseLine, PurchaseLineStatus, PurchaseStatus, Receipt } from 'modules/purchase/models';
import {
    appHealthReducer,
    initializeCostCenterInput,
    initializeCostCenterList,
    orgCostCentersReducer,
    purchaseReducer,
    updateInvoiceLines,
    updatePurchase,
    userPreferencesReducer,
    userReducer,
    updateUserEditStatus
} from 'modules/app/store';
import { InvoiceLine } from 'modules/invoices/models';
import { DeepCopy } from 'modules/purchase/class';
import { CurrencyCode } from 'modules/core/model';
import { mockFreeTextField, mockSelectionField } from '__mocks__/MockedCustomSelectionModel';
import { MemoryRouter } from 'react-router';
import { UserClaims } from 'modules/auth/model';

interface RenderWithStoreProps {
    content: React.ReactNode;
    store?: Store;
    user?: UserClaims;
    purchase?: Purchase;
    invoiceLines?: InvoiceLine[];
    isPR?: boolean;
    fullyBilled?: boolean;
    bigObjective?: boolean;
    purchaseStatus?: PurchaseStatus;
    isCreationPage?: boolean;
    costCentersMap?: initializeCostCenterInput;
    hasValidUserEdit?: boolean;
    isXcm?: boolean;
}

const purchase = {
    purchaseId: '213442134224',
    amount: 80000,
    createdBy: { firstName: 'Jeff', lastName: 'Bezzos', login: 'jeff' },
    requestedBy: { firstName: 'Jagriti', lastName: 'Kumari', login: 'kjagriti' },
    creatorGroup: ['Test'],
    mspOwner: ['mingfeng', 'kulksank', 'srmalvik'],
    creationTime: 1580803200000,
    requestCreationTime: 1580803200000,
    orderCreationTime: 1580803200000,
    status: PurchaseStatus.PURCHASE_ORDER_ISSUED,
    confirmStatus: 4,
    orderNumber: '2D-213442134',
    isPrMspApproved: true,
    customFields: [mockFreeTextField],
    customSelections: [mockSelectionField],
    lines: [
        Object.assign(new PurchaseLine(), {
            amount: 80000,
            lineNumber: 1,
            allocatedAmount: 10000,
            purchaseId: '213442134224',
            description: 'AQI.FIRE.MONITOR - Smart Sense AQI monitor for 6 months',
            currencyCode: CurrencyCode.USD,
            needByDate: 1580803200000,
            lastModifiedTime: 1580803200000,
            purchaseLineStatus: PurchaseLineStatus.UPDATED,
            account: {
                code: '6a0e5178-cc8e-420f-944d-b0c2b20c04cf',
                active: true,
                id: '7a3eef31efbc',
                name: 'Amazon.com, Inc.-SEA37 - Roxanne (Seattle, WA, US)-Procurement'
            },
            receipts: [
                Object.assign(new Receipt(), {
                    receiptNumber: 1,
                    lineNumber: 1,
                    receiptId: 'a63d0a06-221e-4bf6-afa3-cffcb37724dd',
                    receiptAmount: 20000,
                    receiptTime: 1580803200000,
                    allocations: []
                }),
                Object.assign(new Receipt(), {
                    receiptNumber: 2,
                    lineNumber: 1,
                    receiptId: '45412abc-5a1a-40e7-a5ff-76239cd48d45',
                    receiptAmount: 30000,
                    receiptTime: 1586905114708,
                    allocations: []
                }),
                Object.assign(new Receipt(), {
                    receiptNumber: 3,
                    lineNumber: 1,
                    receiptId: '02f0a372-2825-4061-9dad-3d9b07d6e887',
                    receiptAmount: 30000,
                    receiptTime: 1586905114708,
                    allocations: []
                })
            ],
            defaultChannelType: 'access'
        })
    ]
};

const mockedInvoiceLines = [
    Object.assign(new InvoiceLine(), {
        id: '1',
        invoiceId: '12345',
        ofaId: '12345',
        invoiceNumber: '12121',
        lineNumber: 1,
        amount: 1200,
        allocatedAmount: 1200,
        invoiceStatus: 'approved',
        createdAt: 1600171200000,
        dueDate: '2020-12-02T02:00:00Z'
    }),
    Object.assign(new InvoiceLine(), {
        id: '2',
        invoiceId: '12346',
        ofaId: '12346',
        invoiceNumber: '12122',
        lineNumber: 1,
        amount: 500,
        allocatedAmount: 400,
        invoiceStatus: 'ready for payment',
        createdAt: 1600171200000,
        dueDate: '2020-12-02T02:00:00Z',
        servicePeriodStartDate: 1609459200000,
        servicePeriodEndDate: 1619913599000,
        createdAtFns: 1615247999000
    }),
    Object.assign(new InvoiceLine(), {
        id: '3',
        invoiceId: '1232',
        ofaId: '1232',
        invoiceNumber: '12123',
        lineNumber: 1,
        amount: 200,
        allocatedAmount: 180,
        invoiceStatus: 'ready for payment',
        createdAt: 1600171200000,
        dueDate: '2020-12-02T02:00:00Z',
        servicePeriodStartDate: 1609459200000,
        servicePeriodEndDate: 1619913599000,
        createdAtFns: 1615247999000
    })
];

const purchaseRequest = Object.assign(new Purchase(), purchase, { purchaseType: 'PURCHASE_REQUEST' });
const purchaseOrder = Object.assign(new Purchase(), purchase, { purchaseType: 'PURCHASE_ORDER' });
const testUser: UserClaims = { alias: 'test' };
const purchaseFullyBilled = Object.assign(new Purchase(), purchaseOrder, { fullyBilled: true });
const prWithBigObjective = Object.assign(new Purchase(), purchaseRequest, { bigRockObjective: 'alexa' });
const poWithBigObjective = Object.assign(new Purchase(), purchaseOrder, { bigRockObjective: 'alexa' });

function createTestStore(user?: UserClaims) {
    const store = createStore(
        combineReducers({
            preferences: userPreferencesReducer,
            user: userReducer,
            applicationHealth: appHealthReducer,
            purchaseDetails: purchaseReducer,
            orgCostCenters: orgCostCentersReducer
        }),
        { user: user ?? testUser }
    );
    return store;
}

function createTestQueryClient() {
    return new QueryClient({
        defaultOptions: {
            queries: {
                // Turning off retries
                retry: false,
                // Disable refetching
                refetchOnWindowFocus: false,
                // Query data is always fresh once fetched
                staleTime: Infinity
            }
        }
    });
}

export const RenderWithStore = ({
    content,
    purchase,
    user,
    store,
    isPR,
    invoiceLines,
    fullyBilled,
    bigObjective,
    purchaseStatus,
    isCreationPage,
    costCentersMap,
    hasValidUserEdit,
    isXcm = false
}: RenderWithStoreProps) => {
    const __store = createTestStore(user);
    const mockStore = store ? store : __store;
    const queryClient = createTestQueryClient();

    const defaultRecord = isPR ? purchaseRequest : purchaseOrder;
    const purchaseData = purchase ? purchase : defaultRecord;

    const defaultInvoiceLines = isPR ? [] : mockedInvoiceLines;
    const invoiceLinesData = invoiceLines ? invoiceLines : defaultInvoiceLines;
    const defaultUserEdit = hasValidUserEdit ?? true;

    mockStore.dispatch(updatePurchase(purchaseData));

    mockStore.dispatch(updateInvoiceLines({ lineNumber: 1, invoiceLines: invoiceLinesData }));

    if (fullyBilled) {
        mockStore.dispatch(updatePurchase(purchaseFullyBilled));
    }

    if (bigObjective) {
        const record = isPR ? prWithBigObjective : poWithBigObjective;
        mockStore.dispatch(updatePurchase(record));
    }

    if (purchaseStatus) {
        const status = isPR
            ? Object.assign(new Purchase(), purchaseRequest, { status: purchaseStatus })
            : Object.assign(new Purchase(), purchaseOrder, { status: purchaseStatus });
        mockStore.dispatch(updatePurchase(status));
    }

    if (isCreationPage) {
        const creationPagePR = Object.assign(new Purchase(), purchaseRequest, { isPrMspApproved: false });
        mockStore.dispatch(updatePurchase(creationPagePR));
    }

    if (costCentersMap) {
        mockStore.dispatch(initializeCostCenterList(costCentersMap));
    }

    mockStore.dispatch(updateUserEditStatus(defaultUserEdit));

    if (isXcm) {
        const purhcaseXCM = Object.assign(new Purchase(), purchase, { creatorGroup: ['XCM'] });
        mockStore.dispatch(updatePurchase(purhcaseXCM));
    }

    return (
        <Provider store={mockStore}>
            <QueryClientProvider client={queryClient}>{content}</QueryClientProvider>
        </Provider>
    );
};

type RenderWithStoreAndRouterProps = RenderWithStoreProps & {
    route?: string;
};

export const RenderWithStoreAndRouter = ({
    content,
    purchase,
    store,
    isPR,
    invoiceLines,
    fullyBilled,
    bigObjective,
    purchaseStatus,
    isCreationPage,
    costCentersMap,
    hasValidUserEdit,
    isXcm = false,
    route = '/'
}: RenderWithStoreAndRouterProps) => {
    return (
        <MemoryRouter initialEntries={[route]} future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
            <RenderWithStore
                content={content}
                purchase={purchase}
                store={store}
                isPR={isPR}
                invoiceLines={invoiceLines}
                fullyBilled={fullyBilled}
                bigObjective={bigObjective}
                purchaseStatus={purchaseStatus}
                isCreationPage={isCreationPage}
                costCentersMap={costCentersMap}
                hasValidUserEdit={hasValidUserEdit}
                isXcm={isXcm}
            />
        </MemoryRouter>
    );
};

export const mockedPurchase = DeepCopy.purchase((purchase as unknown) as Purchase);
