import { Auth } from '@aws-amplify/auth';

const customHeader = 'custom_header';

export const AuthConfig = {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ['openid'],
        redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN_URI,
        redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT_URI,
        responseType: 'code'
    }
};

export const ApiEndpoints = [
    {
        name: 'MERP_API',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        [customHeader]: async (): Promise<{ Authorization: string }> => ({
            Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        })
    },
    {
        name: 'MERP_REPORTS',
        endpoint: process.env.REACT_APP_REPORTS_API_ENDPOINT,
        [customHeader]: async (): Promise<{ Authorization: string }> => ({
            Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        })
    }
];

export const AppConfig = {
    troubleTicketLink: process.env.REACT_APP_TICKET_CREATION_URL,
    coupaLink: process.env.REACT_APP_COUPA_URL,
    userGuidesLink: process.env.REACT_APP_USER_GUIDES_URL,
    coupaAccessFormUrl: process.env.REACT_APP_COUPA_ACCESS_FORM,
    showReactQueryDevTools: process.env.REACT_APP_SHOW_REACT_QUERY_DEV_TOOLS === 'true',
    reactQueryCacheBuster: process.env.REACT_APP_REACT_QUERY_CACHE_BUSTER
};
