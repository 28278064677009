import React from 'react';
import BoardItem from '@amzn/awsui-board-components/board-item';
import { Card, i18nStrings } from './Card';
import { Header } from '@amzn/awsui-components-react';

export const TopAccounts = () => {
    return (
        <BoardItem
            i18nStrings={i18nStrings}
            header={
                <Header data-header-id={Card.TopAccounts}>
                    Top 20 account, cost center, business PO requestor combinations with receiving defects
                </Header>
            }
        />
    );
};
