/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Link } from '@amzn/awsui-components-react';

import { PurchaseIndex } from '@amzn/merp-core/models/purchase';
import { TableRow } from 'modules/core/model';
import { ExpandableCell } from 'modules/core/components/Table';

import styles from './purchaseToggleCell.module.scss';

interface PurchaseToggleCellProps {
    row: TableRow<PurchaseIndex>;
    onChange?: (rowNumber: number) => void;
}

export const PurchaseToggleCell = ({ row, onChange }: PurchaseToggleCellProps) => {
    const mapToLineDescriptions = (row: TableRow<PurchaseIndex>) => (
        <>
            {row.payload.purchaseLines &&
                row.payload.purchaseLines.map(({ lineDescription }, index) => (
                    <React.Fragment key={index}>
                        <div className={styles.ellipsis}>{lineDescription}</div>
                    </React.Fragment>
                ))}
        </>
    );

    const { payload } = row;

    const id = payload.purchaseType === 'PURCHASE_REQUEST' ? payload.purchaseId : payload.orderNumber;
    const path =
        payload.purchaseType === 'PURCHASE_REQUEST'
            ? `/purchaseRequest/${payload.purchaseId}`
            : `/purchaseOrder/${payload.purchaseId}`;
    const url = new URL(path, window.location.origin);

    return (
        <ExpandableCell
            row={row}
            control={true}
            cell={
                <Box padding={{ top: 'xxs' }}>
                    <Link variant="secondary" href={url.href} target="_blank">
                        {id}
                    </Link>
                </Box>
            }
            content={mapToLineDescriptions(row)}
            onChange={onChange}
        />
    );
};
