import { ConfirmStatus, PurchaseType } from '@amzn/merp-core/models';
import { API } from '@aws-amplify/api';
import { useQuery } from '@tanstack/react-query';

export enum PurchaseLineCountType {
    PENDING_RECEIVING = 'pending-receiving',
    OVER_INVOICED = 'over-invoiced'
}

export function useCards() {
    const usePurchaseCount = (purchaseType: PurchaseType, confirmStatus: ConfirmStatus) => {
        return useQuery<number>({
            queryKey: ['purchases', 'count', purchaseType, confirmStatus],
            queryFn: () =>
                API.get('MERP_API', `/purchases/count?purchaseType=${purchaseType}&confirmStatus=${confirmStatus}`, {}),
            staleTime: 1000 * 60 * 5
        });
    };

    const useFullyReceivedPurchaseCount = () => {
        return useQuery<number>({
            queryKey: ['purchases', 'count', 'fully-received'],
            queryFn: () => API.get('MERP_API', '/purchases/count/fully-received', {}),
            staleTime: 1000 * 60 * 15
        });
    };

    const usePurchaseLineCount = (countType: PurchaseLineCountType) => {
        return useQuery<number>({
            queryKey: ['purchaseLines', 'count', countType],
            queryFn: () => API.get('MERP_API', `/purchaseLines/count/${countType}`, {}),
            staleTime: 1000 * 60 * 5
        });
    };

    return { usePurchaseCount, usePurchaseLineCount, useFullyReceivedPurchaseCount };
}
